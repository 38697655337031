<script setup lang="ts">
import type { Badge } from "@/types";
const { t } = useT();

const props = defineProps<{
	bestDeal?: boolean;
	mostPopular?: boolean;
	icon?: string;
	title?: string;
	badge?: Badge;
}>();

const displayText = computed(() => {
	if (props.badge?.labelText) {
		return props.badge.labelText;
	} else if (props.mostPopular) {
		return t("Most popular");
	}
	return t("Best deal");
});

const bg = computed(() => (props.badge?.labelBackground ? props.badge?.labelBackground : "var(--overlay-medium)"));
const color = computed(() => (props.badge?.labelTextColor ? props.badge?.labelTextColor : "var(--neutral-100)"));
const border = computed(() => (props.badge?.labelBorderColor ? props.badge?.labelBorderColor : "var(--overlay-dark)"));
</script>

<template>
	<AText v-if="badge?.labelText || bestDeal || mostPopular || title" :size="18" class="label" :modifiers="['bold']">
		<NuxtIcon v-if="icon" :name="icon" filled />
		{{ title || displayText }}
	</AText>
</template>

<style scoped lang="scss">
.label {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	gap: 4px;
	max-width: 100%;
	min-width: 204px;
	height: 44px;
	padding: 8px 16px;
	overflow: hidden;
	border-radius: 0 0 12px 12px;
	box-shadow:
		0px 10px 15px -3px rgba(2, 6, 23, 0.1),
		0px 4px 6px -2px rgba(2, 6, 23, 0.05);
	border: 1px solid v-bind(border);
	border-top-width: 0px;
	background: v-bind(bg);
	color: v-bind(color);

	&:deep(path) {
		fill: v-bind(color);
	}
}
</style>
